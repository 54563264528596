import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"
import "firebase/compat/functions"

const firebaseConfig = {
  apiKey: "AIzaSyA8p1ki52IF26V10iUMSMsMqYRw5Te7cnA",
  authDomain: "rayze-dev-e25ce.firebaseapp.com",
  projectId: "rayze-dev-e25ce",
  storageBucket: "rayze-dev-e25ce.appspot.com",
  messagingSenderId: "521302816777",
  appId: "1:521302816777:web:a3e31a3f2bb08123cba0d8",
  measurementId: "G-98T4NJ5R3J",
}
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)

export const auth = app.auth()
export const firestore = app.firestore()
export const storage = app.storage()
export const functions = app.functions()

export default app
// export default firebase;
