import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { TouchableOpacity } from "react-native-web";
import { useCRM } from "context/CRMContext";
import { FullModal } from "components/Modals/FullModal";
import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import {
  CRM_ContactStatus,
  CRM_LeadSources,
  CRM_NumOfEmployees,
  CRM_Status,
} from "constants/Data";
import { getFullLocation, getUsersName } from "utils/helpers";
import * as FiIcons from "react-icons/fi";
import * as RiIcons from "react-icons/ri";
import { useRayze } from "context/RayzeContext";
import Geocode from "react-geocode";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { searchOrg } from "services/organizations-service";
import { firestore } from "utils/firebase";

export const ContactForm = ({
  contact,
  setContact,
  showModal,
  setShowModal,
  onSave,
  title,
}) => {
  const { linkedOrg, setLinkedOrg } = useCRM();
  const { team } = useRayze();

  const [orgSearch, setOrgSearch] = useState("");
  const [searchedOrgs, setSearchedOrgs] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  //address
  const [value, setValue] = useState(null);

  //ein
  const [einError, setEinError] = useState(null);

  useEffect(() => {
    console.log(contact);
    setSaveDisabled(
      !contact.name ||
        !contact.ein ||
        einError ||
        !contact.status ||
        !contact.leadOwner ||
        // !contact.estimatedRevenue ||
        !contact.website ||
        !contact?.address?.city ||
        !contact?.address?.state ||
        !contact?.contacts ||
        !contact?.contacts?.length === 0 ||
        // !contact?.contacts[0]?.name ||
        !contact?.contacts[0]?.email
    );
  }, [contact, einError]);

  useEffect(() => {
    const city = contact?.address?.city || null;

    city ? setValue({ label: getFullLocation(contact) }) : setValue(null);
  }, [contact.address]);

  useEffect(() => {
    setEinError(null);
    if (!contact?.ein) return;
    if (contact.ein?.length !== 10) return;
    firestore
      .collection("crm")
      .where("ein", "==", contact.ein)
      .get()
      .then((querySnapshot) => {
        if (
          querySnapshot.docs.length > 0 &&
          contact?.id !== querySnapshot.docs[0].id
        ) {
          setEinError("Contact already exists");
        } else {
          setEinError(null);
        }
      });
  }, [contact?.ein]);

  useEffect(() => {
    if (!orgSearch || !showModal) {
      setOrgSearch("");
      setSearchedOrgs([]);
    } else {
      searchOrg(orgSearch, setSearchedOrgs);
    }
  }, [orgSearch, showModal]);

  function editContacts(index, prop, value) {
    let contacts = contact?.contacts || [{ status: 1 }];
    contacts[index][prop] = value;
    setContact({ ...contact, contacts });
  }

  //ADDRESS
  const getLatLong = (searchText) => {
    setValue(searchText);
    if (!searchText) return;
    Geocode.fromAddress(searchText.label)
      .then((json) => {
        var addressComponent = json.results[0].address_components;
        var streetNumber;
        var fullAddress;
        var city;
        var state;
        var zipCode;
        const latLng = json.results[0].geometry.location;
        addressComponent.forEach((comp, index) => {
          let types = comp.types;
          console.log('comp', comp)
          if (types.find((x) => x === "street_number")) {
            streetNumber = comp.long_name + " ";
          } else if (types.find((x) => x === "route")) {
            if (streetNumber !== null) {
              fullAddress = streetNumber + comp.long_name;
            }
          } else if (types.find((x) => x === "locality" || x === 'sublocality')) {
            city = comp.long_name;
          } else if (!city) {
            if (types.find((x) => x === 'administrative_area_level_2')) {
              city = (comp.long_name); //city
            }
          } else if (types.find((x) => x === "administrative_area_level_1")) {
            state = comp.long_name;
          } else if (types.find((x) => x === "postal_code")) {
            zipCode = comp.long_name;
          }
        });
        setContact({
          ...contact,
          address: {
            line1: fullAddress || "",
            city: city,
            zipCode: zipCode || "",
            state: state,
          },
          latLng: latLng,
        });
      })
      .catch((e) => {
        console.log("error getting lat lng", e);
      });
  };

  const leadOptions = team.map((x) => {
    return { value: x.id, label: getUsersName(x) };
  });

  return (
    <>
      {showModal && (
        <FullModal title={title} setShowModal={setShowModal}>
          {/** LINK TO IN-APP ORG */}
          <View style={styles.settingsRow}>
            {contact?.linkedOrg ? (
              <View style={{ flex: 1 }}>
                <PrimaryText
                  fontSize={14}
                  fontWeight={700}
                  style={styles.inputTitle}
                >
                  {"Link to In-App Organization"}
                </PrimaryText>
                <View style={styles.selectedLinkedOrg}>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={300}
                    style={styles.text}
                  >
                    {linkedOrg?.name}
                  </PrimaryText>
                  <TouchableOpacity
                    onPress={() => {
                      setContact({ ...contact, linkedOrg: null });
                      setOrgSearch("");
                    }}
                  >
                    <FiIcons.FiX size={20} color={COLORS.primary.darkGray} />
                  </TouchableOpacity>
                </View>
              </View>
            ) : (
              <CustomInput
                title={"Link to In-App Organization"}
                placeholder={"Search organization..."}
                onChangeText={(text) => setOrgSearch(text)}
                value={orgSearch}
              />
            )}
          </View>

          {!!orgSearch && (
            <View style={styles.searchContainer}>
              {searchedOrgs.map((item) => {
                return (
                  <TouchableOpacity
                    key={item?.id}
                    style={styles.searchedItem}
                    onPress={() => {
                      setLinkedOrg(item);
                      setContact({ ...contact, linkedOrg: item?.id });
                      setOrgSearch("");
                    }}
                  >
                    <PrimaryText fontSize={12} fontWeight={400}>
                      {item?.name}
                    </PrimaryText>
                  </TouchableOpacity>
                );
              })}
            </View>
          )}

          {/** NAME */}
          <View style={styles.settingsRow}>
            <CustomInput
              title={"Name"}
              mandatory
              placeholder={"Organization's name"}
              onChangeText={(text) => setContact({ ...contact, name: text })}
              value={contact.name}
            />
          </View>

          {/** EIN */}
          <View style={styles.settingsRow}>
            <CustomInput
              title={"EIN"}
              mandatory
              placeholder={"Organization's EIN xx-xxxxxxx"}
              onChangeText={(text) => {
                let trimmed = text.length > 10 ? text.substring(0, 10) : text;
                if (trimmed?.length > 2) {
                  trimmed = trimmed.replace("-", "");
                  trimmed =
                    trimmed.substring(0, 2) + "-" + trimmed.substring(2, 10);
                }

                setContact({
                  ...contact,
                  ein: trimmed.toUpperCase(),
                });
              }}
              value={contact.ein}
              errorMessage={einError}
            />
          </View>

          {/** STATUS */}
          <View style={[styles.settingsRow, { zIndex: 999 }]}>
            <CustomPicker
              title={"Status"}
              mandatory
              options={CRM_Status}
              onChange={(value) => setContact({ ...contact, status: value })}
              value={contact.status}
            />
          </View>

          {/** LEAD OWNER */}
          <View style={[styles.settingsRow, { zIndex: 999 }]}>
            <CustomPicker
              title={"Lead Owner"}
              mandatory
              options={leadOptions}
              onChange={(value) => setContact({ ...contact, leadOwner: value })}
              value={contact.leadOwner}
            />
          </View>

          {/** LEAD SOURCE */}
          <View style={[styles.settingsRow, { zIndex: 999 }]}>
            <CustomPicker
              title={"Lead Source"}
              options={CRM_LeadSources}
              onChange={(value) =>
                setContact({ ...contact, leadSource: value })
              }
              value={contact.leadSource}
            />
          </View>

          {/** CONTACTS */}
          <View
            style={{
              flex: 1,
              borderWidth: 1,
              borderColor: COLORS.primary.lightGray,
              borderRadius: 12,
              marginHorizontal: 12,
              //   paddingBottom: 8,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                paddingVertical: 8,
                paddingRight: 4,
              }}
            >
              <PrimaryText
                fontSize={14}
                fontWeight={700}
                style={styles.inputTitle}
              >
                {"Contact(s)"}
              </PrimaryText>
              <TouchableOpacity
                onPress={() => {
                  let contacts = contact?.contacts || [{ status: 1 }];
                  if (contacts?.length > 0) {
                    contacts.push({ status: 1 });
                  }
                  setContact({ ...contact, contacts });
                }}
              >
                <FiIcons.FiPlusCircle size={20} />
              </TouchableOpacity>
            </View>
            <View style={{ flex: 1, paddingHorizontal: 12, marginLeft: 0 }}>
              {contact?.contacts?.length > 0
                ? contact?.contacts?.map((item, index) => {
                    return (
                      <Contact
                        item={item}
                        index={index}
                        key={index}
                        setContact={setContact}
                        editContacts={editContacts}
                      />
                    );
                  })
                : editContacts(0, "name", "")}
            </View>
          </View>

          {/** EMPLOYEES */}
          <View style={[styles.settingsRow, { zIndex: 999 }]}>
            <CustomPicker
              title={"Number of Employees"}
              options={CRM_NumOfEmployees}
              onChange={(value) =>
                setContact({ ...contact, numOfEmployees: value })
              }
              value={contact.numOfEmployees}
            />
          </View>

          {/** REVENUE */}
          <View style={[styles.settingsRow, { zIndex: 999 }]}>
            <CustomInput
              // mandatory
              title={"Estimated Revenue in USD"}
              placeholder={"Organization's estimated yearly revenue"}
              onChangeText={(text) =>
                setContact({
                  ...contact,
                  estimatedRevenue: text.replace(/[^0-9+]/g, ""),
                })
              }
              value={contact.estimatedRevenue || ""}
            />
          </View>

          {/** WEBSITE */}
          <View style={styles.settingsRow}>
            <CustomInput
              mandatory
              title={"Website"}
              placeholder={"Organization's website"}
              onChangeText={(text) => setContact({ ...contact, website: text })}
              value={contact.website}
            />
          </View>

          {/** ADDRESS */}
          <View style={[styles.settingsRow, { zIndex: 999 }]}>
            <View style={{ flex: 1, zIndex: 999 }}>
              <PrimaryText
                fontSize={14}
                fontWeight={700}
                style={styles.inputTitle}
              >
                {"Address*"}
              </PrimaryText>

              <GooglePlacesAutocomplete
                selectProps={{
                  isClearable: true,
                  value: value,
                  onChange: getLatLong,
                  styles: {
                    dropdownIndicator: false,
                    option: (provided) => ({
                      ...provided,
                      color: COLORS.primary.darkGray,
                      // height: 40,
                      paddingHorizontal: 16,
                      fontSize: 12,
                      fontWeight: 300,
                      backgroundColor: COLORS.primary.white,
                      fontFamily: "Montserrat",
                    }),
                    control: (provided) => {
                      // console.log(provided)
                      return {
                        ...provided,
                        minHeight: undefined,
                        padding: 0,
                        // paddingBottom: 10,
                        // margin: 0,
                        borderColor: COLORS.primary.lightGray,
                        borderWidth: 1,
                        backgroundColor: COLORS.primary.offWhite,
                        borderRadius: 15,
                        overflow: "hidden",
                        height: 30,
                        fontSize: 13,
                        fontWeight: 300,
                        fontFamily: "Montserrat",
                      };
                    },
                    indicatorsContainer: (provided) => {
                      return {};
                    },
                    clearIndicator: (provided) => {
                      // console.log("clear", provided);
                      return {
                        ...provided,
                        padding: 0,
                        marginTop: "12%",
                        boxSizing: undefined,
                        display: undefined,
                      };
                    },
                    dropdownIndicator: (provided) => {
                      return {
                        ...provided,
                        padding: 0,
                        marginRight: 4,
                      };
                    },
                    placeholder: (provided) => {
                      return {
                        ...provided,
                      };
                    },
                    singleValue: (provided, state) => {
                      // console.log("sing value", provided);
                      return {
                        ...provided,
                        paddingLeft: 2,
                        fontSize: 14,
                        fontWeight: 300,
                        fontFamily: "Montserrat",
                        // backgroundColor: "red",
                      };
                    },
                    valueContainer: (provided, state) => {
                      // console.log('value cont', provided)
                      return {
                        ...provided,
                        top: state.hasValue ? "-34%" : undefined,
                      };
                    },
                  },
                  placeholder: "Select address...",
                }}
                apiKey={"AIzaSyDR0wfcPs5HkIoR4iJTVP8K-srlpOn-Yhs"}
              />
              {!contact?.address?.city && (
                <View style={styles.errorContainer}>
                  <RiIcons.RiAlertFill
                    width={16}
                    height={16}
                    fill={COLORS.primary.error}
                    stroke={COLORS.primary.white}
                  />
                  <PrimaryText
                    fontSize={12}
                    fontWeight={400}
                    style={styles.errorMessage}
                  >
                    {"Invalid address."}
                  </PrimaryText>
                </View>
              )}
            </View>
          </View>

          {/** NOTES */}
          <View style={[styles.settingsRow, { zIndex: 998 }]}>
            <CustomInput
              multiline
              title={"Notes"}
              placeholder={""}
              onChangeText={(text) => setContact({ ...contact, notes: text })}
              value={contact.notes}
            />
          </View>

          {/** SAVE */}
          <TouchableOpacity
            style={[
              styles.button2,
              {
                opacity: saveDisabled ? 0.4 : 1,
                width: 100,
              },
            ]}
            onPress={onSave}
            disabled={saveDisabled}
          >
            <PrimaryText
              fontSize={16}
              fontWeight={800}
              style={{ color: COLORS.primary.white }}
            >
              {"Save"}
            </PrimaryText>
          </TouchableOpacity>
        </FullModal>
      )}
    </>
  );
};

const Contact = ({ item, contact, index, editContacts, setContact }) => {
  const [emailError, setEmailError] = useState(null);

  useEffect(() => {
    setEmailError(!validateEmail(item.email));
  }, [item.email]);

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
      }}
      key={index.toString()}
    >
      <TouchableOpacity
        onPress={() => {
          let contacts = contact?.contacts || [{}];
          if (contacts?.length > 0) {
            contacts.splice(index, 1);
          }
          setContact({ ...contact, contacts });
        }}
        disabled={contact?.contacts?.length === 1}
      >
        <FiIcons.FiX size={20} />
      </TouchableOpacity>
      <View style={{ flex: 1, marginBottom: 24 }}>
        <CustomInput
          title={"Name"}
          placeholder={"Contacts's name"}
          onChangeText={(text) => editContacts(index, "name", text)}
          value={item.name}
          style={{ marginBottom: 12 }}
        />
        <CustomInput
          title={"Title"}
          placeholder={"Contacts's title"}
          onChangeText={(text) => editContacts(index, "title", text)}
          value={item.title}
          style={{ marginBottom: 12 }}
        />
        <CustomInput
          mandatory
          title={"Email"}
          placeholder={"Contacts's email"}
          onChangeText={(text) => editContacts(index, "email", text)}
          value={item.email}
          style={{ marginBottom: 12 }}
          errorMessage={emailError ? 'Invalid email' : null}
        />
        <CustomInput
          title={"Phone"}
          placeholder={"Contact's phone"}
          onChangeText={(text) => editContacts(index, "phone", text)}
          value={item.phone}
          style={{ marginBottom: 12 }}
        />
        {/** STATUS */}
        <CustomPicker
          title={"Status"}
          mandatory
          options={CRM_ContactStatus}
          onChange={(value) => editContacts(index, "status", value)}
          value={item.status}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: {
    width: "100%",
    // backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    paddingVertical: 12,
  },
  header: {
    color: COLORS.primary.darkBlue,
  },
  subtitle: {
    color: COLORS.primary.darkBlue,
    marginHorizontal: 24,
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    borderRadius: 40,
  },
  button2: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    // width: 100,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: 8,
    margin: 12,
    borderRadius: 40,
  },
  settingsRow: {
    flexDirection: "row",
    paddingHorizontal: 13,
    paddingVertical: 8,
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottomColor: COLORS.primary.lightGray,
    // borderBottomWidth: 0.5,
  },
  searchContainer: {
    marginHorizontal: 16,
    padding: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  searchedItem: {
    padding: 4,
  },
  inputTitle: {
    paddingLeft: 10,
    marginBottom: 5,
    color: COLORS.primary.darkGray,
  },
  selectedLinkedOrg: {
    flexDirection: "row",
    height: 30,
    borderRadius: 13,
    flexGrow: 1,
    borderWidth: 1,
    borderColor: COLORS.primary.lightGray,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    paddingRight: 4,
    backgroundColor: COLORS.primary.offWhite,
  },
  text: {
    fontSize: 14,
    color: COLORS.primary.darkGray,
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
});
