import React, { useState, useEffect, useMemo, useRef } from "react";
import { StyleSheet, View } from "react-native";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useDashboard } from "context/DashboardContext";
import { useNavigate } from "react-router";
import * as FaIcons from "react-icons/fa";
import { TouchableOpacity } from "react-native-web";
import { useCRM } from "context/CRMContext";
import { CRMCard } from "components/Cards/CRMCard";

import { CustomInput } from "components/Inputs/CustomInput";
import { CustomPicker } from "components/Inputs/CustomPicker";
import { useRayze } from "context/RayzeContext";
import { useAuth } from "context/AuthContext";
import { ControlledMenu, MenuDivider } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { getUsersName } from "utils/helpers";
import { usePBC } from "context/PBCContext";
import { PBCCard } from "components/Cards/PBCCard";
import {
  updateDonationAsCheckCashed,
  updateDonationAsCheckSent,
  updateDonationAsContacted,
} from "services/pbc-service";
import { set } from "lodash";

const pageSize = 10;
const dateOptions = [
  { value: "all", label: "All" },
  { value: "thisWeek", label: "This Week" },
  { value: "lastWeek", label: "Last 2 Weeks" },
];

export const PBC = () => {
  const navigate = useNavigate();
  const { team } = useRayze();
  const { recentDonations, page, setPage, getDonations, nextPage } = usePBC();

  const [search, setSearch] = useState("");

  //filter
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({ date: "all", lead: null });
  const [filtering, setFiltering] = useState(false);
  const [recentDonationsFiltered, setRecentDonationsFiltered] = useState([]);
  const filterRef = useRef(null);

  useEffect(() => {
    getDonations();
  }, []);

  useEffect(() => {
    if (search && !(filter.date !== "all" || filter.lead)) {
      setFiltering(false);
    } else if (filter.date !== "all" || filter.lead) {
      setFiltering(true);
    } else {
      setFiltering(false);
    }
  }, [search, filter]);

  useEffect(() => {
    setPage(1);
  }, [search, filter]);

  useEffect(() => {
    setRecentDonationsFiltered(currentTableData());
  }, [recentDonations, page, search, filter]);

  const currentTableData = () => {
    const firstPageIndex = (page - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return recentDonations.slice(firstPageIndex, lastPageIndex);
  };

  const totalCount =
    search || filtering ? recentDonations?.length : recentDonations?.length;
  const totalPageCount = Math.ceil(totalCount / pageSize);
  const startCount = (page - 1) * pageSize + 1;
  const endCount = startCount + currentTableData()?.length - 1;

  const teamOptions = useMemo(() => {
    let temp = [{ value: null, label: "All" }];
    const mappedTeam = team?.map((x) => {
      return { value: x.id, label: getUsersName(x) };
    });
    return temp.concat(mappedTeam);
  }, [team]);

  const totalAmount = recentDonations?.reduce((a, b) => a + b.amount, 0);
  const totalFees = recentDonations?.reduce(
    (a, b) => (a + (b.fee ? b.fee : 0)),
    0
  );
  const totalNet = totalAmount - totalFees;
  // console.log("testing", totalAmount, totalFees, totalNet);
  const checksCashed = recentDonations?.filter((x) => x.checkCashed);
  const totalChecksCashed= checksCashed?.reduce((a, b) => a + (b.amount - (b.fee ? b.fee : 0)), 0);

  return (
    <View style={styles.tableContainer}>
      <View
        style={{
          zIndex: 999,
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 12,
        }}
      >
        <View
          style={{ flexDirection: "row", alignItems: "center", flexGrow: 1 }}
        >
          <CustomInput
            placeholder={"Search"}
            style={{ flexGrow: 1, maxWidth: 400, marginLeft: 12 }}
            onChangeText={setSearch}
            value={search}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <button
            ref={filterRef}
            style={{
              backgroundColor: "transparent",
              border: 0,
              cursor: "pointer",
            }}
            onClick={() => setOpen(!open)}
            onPointerEnter={() => setOpen(true)}
          >
            <FaIcons.FaEllipsisV size={24} color={COLORS.primary.darkBlue} />
          </button>
          <ControlledMenu
            anchorRef={filterRef}
            state={open ? "open" : "closed"}
            transition
            onItemClick={(e) => (e.keepOpen = true)}
          >
            <View style={{ paddingHorizontal: 12 }}>
              <CustomPicker
                title={"Date Created"}
                value={filter.date}
                onChange={(value) => setFilter({ ...filter, date: value })}
                options={dateOptions}
                style={{ width: 200 }}
              />
            </View>
            <MenuDivider />
            <View style={{ paddingHorizontal: 12 }}>
              <CustomPicker
                title="Lead Owner"
                value={filter.lead}
                onChange={(value) => setFilter({ ...filter, lead: value })}
                options={teamOptions}
                style={{
                  width: 200,
                  flexGrow: undefined,
                  alignSelf: "flex-end",
                }}
              />
            </View>
          </ControlledMenu>
        </View>
      </View>

      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: 24,
          marginTop: 12,
        }}
      ></View>

      <View style={[styles.info, { marginTop: 24 }]}>
        <View style={styles.stats}>
          <View style={styles.statContainer}>
            <PrimaryText fontSize={18} fontWeight={400} style={styles.header}>
              {"Net Owed"}
            </PrimaryText>
            <PrimaryText fontSize={24} fontWeight={700} style={styles.header}>
              {totalNet}
            </PrimaryText>
          </View>
          <View style={styles.statContainer}>
            <PrimaryText fontSize={18} fontWeight={400} style={styles.header}>
              {"Total Cashed"}
            </PrimaryText>
            <PrimaryText fontSize={24} fontWeight={700} style={styles.header}>
              {totalChecksCashed}
            </PrimaryText>
          </View>
        </View>
      </View>

      <Table
        className="table"
        style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
      >
        <TableHead>
          <TableRow>
            <TableCell>PaymentIntentId</TableCell>
            <TableCell>Nonprofit</TableCell>
            <TableCell>EIN</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Net Amount</TableCell>
            <TableCell>Summed Net</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Contacted</TableCell>
            <TableCell>Onboarded</TableCell>
            <TableCell>Check Sent</TableCell>
            <TableCell>Check Cashed</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {recentDonationsFiltered &&
            recentDonationsFiltered.map((item, i) => {
              return (
                <PBCCard
                  item={item}
                  key={item.id}
                  onContacted={() => {
                    updateDonationAsContacted(item.id, !item.contacted, () => {
                      let temp = [...recentDonationsFiltered];
                      temp[i].contacted = !item.contacted;
                      setRecentDonationsFiltered(temp);
                    });
                  }}
                  onCheckSent={() => {
                    updateDonationAsCheckSent(item.id, !item.checkSent, () => {
                      let temp = [...recentDonationsFiltered];
                      temp[i].checkSent = !item.checkSent;
                      setRecentDonationsFiltered(temp);
                    });
                  }}
                  onCheckCashed={() => {
                    updateDonationAsCheckCashed(
                      item.id,
                      !item.checkCashed,
                      () => {
                        let temp = [...recentDonationsFiltered];
                        temp[i].checkCashed = !item.checkCashed;
                        setRecentDonationsFiltered(temp);
                      }
                    );
                  }}
                  summedNet={recentDonations.filter(x => x?.org?.ein === item?.org?.ein).reduce( (a, b) => a + (b.amount - (b.fee ? b.fee : 0)), 0)}
                />
              );
            })}
        </TableBody>
      </Table>
      <View
        style={{ flexDirection: "row", alignSelf: "flex-end", marginTop: 12 }}
      >
        <TouchableOpacity onPress={() => (page > 1 ? setPage(page - 1) : null)}>
          <FaIcons.FaChevronLeft size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>

        <PrimaryText fontSize={14} fontWeight={400} style={styles.subtitle}>
          {`${startCount} - ${endCount} of ${totalCount}`}
        </PrimaryText>

        <TouchableOpacity
          onPress={() => (page < totalPageCount ? nextPage() : null)}
        >
          <FaIcons.FaChevronRight size={20} color={COLORS.primary.darkGray} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  tableContainer: {
    width: "100%",
    // backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    borderTopLeftRadius: 0,
    paddingVertical: 12,
  },
  header: {
    color: COLORS.primary.darkBlue,
  },
  subtitle: {
    color: COLORS.primary.darkBlue,
    marginHorizontal: 24,
  },
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    width: 200,
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    borderRadius: 40,
  },
  button2: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    // width: 100,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: 8,
    margin: 12,
    borderRadius: 40,
  },
  settingsRow: {
    flexDirection: "row",
    paddingHorizontal: 13,
    paddingVertical: 8,
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottomColor: COLORS.primary.lightGray,
    // borderBottomWidth: 0.5,
  },
  searchContainer: {
    marginHorizontal: 16,
    padding: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  searchedItem: {
    padding: 4,
  },
  inputTitle: {
    paddingLeft: 10,
    marginBottom: 5,
    color: COLORS.primary.darkGray,
  },
  selectedLinkedOrg: {
    flexDirection: "row",
    height: 30,
    borderRadius: 13,
    flexGrow: 1,
    borderWidth: 1,
    borderColor: COLORS.primary.lightGray,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    paddingRight: 4,
    backgroundColor: COLORS.primary.offWhite,
  },
  text: {
    fontSize: 14,
    color: COLORS.primary.darkGray,
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
  info: {
    flexDirection: "row",
    marginBottom: 16,
    maxHeight: 300,
  },
  infoContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
  },
  infoTextRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 6,
  },
  stats: {
    flex: 1,
    flexDirection: "row",
    padding: 27,
    justifyContent: "space-around",
    // justifyContent:'center',
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    // marginLeft: 16,
  },
  statContainer: {
    alignItems: "center",
  },
});
