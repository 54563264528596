import React, { createContext, useState, useEffect, useContext } from "react";
import { useCauses } from "./CausesContext";
import { getOurCauses } from "services/causes-service";
import { fetchMoreContacts, getCRMLogs } from "services/crm-service";
import { getOrg } from "services/organizations-service";
import { useAuth } from "./AuthContext";

export const CRMContext = createContext();

export const CRMProvider = ({ children }) => {
  const { allCauses } = useCauses();
  const { currentUser } = useAuth();

  const [lastDoc, setLastDoc] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [getContacts, setGetContacts] = useState(false);
  const [page, setPage] = useState(1);

  const [selectedContact, setSelectedContact] = useState(null);
  const [linkedOrg, setLinkedOrg] = useState(null);
  const [causes, setCauses] = useState(null);
  const [logs, setLogs] = useState(null);

  //new contact
  const [newContact, setNewContact] = useState({status: 1, leadOwner: currentUser?.id});

  useEffect(() => {
    if (getContacts) {
      fetchMoreContacts(lastDoc, setLastDoc, contacts, setContacts);
    }
  }, [getContacts]);

  function nextPage() {
    fetchMoreContacts(lastDoc, setLastDoc, contacts, setContacts);
    setPage(page + 1);
  }

  //set selected user causes
  useEffect(() => {
    if (!selectedContact) setLinkedOrg(null);
    if (!selectedContact || !allCauses) return;
    getOurCauses(selectedContact, allCauses, setCauses);
    if (selectedContact?.linkedOrg) {
      getOrg(selectedContact?.linkedOrg, setLinkedOrg);
    } else {
      setLinkedOrg(null);
    }
    const logSub = getCRMLogs(selectedContact, setLogs);
    return () => {
      logSub();
    };
  }, [selectedContact, allCauses]);

  return (
    <CRMContext.Provider
      value={{
        nextPage,
        contacts,
        setContacts,
        setGetContacts,
        selectedContact,
        setSelectedContact,
        causes,
        page,
        setPage,
        newContact,
        setNewContact,
        linkedOrg,
        setLinkedOrg,
        logs,
      }}
    >
      {children}
    </CRMContext.Provider>
  );
};

export const useCRM = () => useContext(CRMContext);
